import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FloatingButton } from '@entur/button';
import { Label } from '@entur/typography';
import { space } from '@entur/tokens';
import { Props } from 'docz';
import { AddIcon, CheckIcon, RightArrowIcon, ForwardIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import FabExample from './FabExample.png';
import FabDo from './FabDo.png';
import FabDont from './FabDont.png';
import { flytendeknapper } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<FloatingButton></FloatingButton>'} __scope={{
      props,
      DefaultLayout,
      FloatingButton,
      Label,
      space,
      Props,
      AddIcon,
      CheckIcon,
      RightArrowIcon,
      ForwardIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DoDontGroup,
      DoDontCard,
      FabExample,
      FabDo,
      FabDont,
      flytendeknapper,
      DefaultLayout,
      _frontmatter
    }} props={flytendeknapper} mdxType="Playground">
  <FloatingButton mdxType="FloatingButton"></FloatingButton>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "floatingbutton"
    }}>{`FloatingButton`}</h3>
    <ImportStatement imports="FloatingButton" mdxType="ImportStatement" />
    <Props of={FloatingButton} mdxType="Props" />
    <p><inlineCode parentName="p">{`<FloatingButton />`}</inlineCode>{` kan ha et ikon, eller ikon og tekst. Ikonet kan være til venstre eller til høyre for teksten.`}</p>
    <p>{`Denne typen knapp bør være "sticky" på toppen av eksisterende innhold, men denne posisjoneringen må du gjøre selv - enten ved hjelp av en CSS-klasse, eller med inline styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<FloatingButton
  {...otherProps}
  style={{ position: 'fixed', top: '1em', right: '1em' }}
/>
`}</code></pre>
    <p>{`Du bør ha en eller `}<em parentName="p">{`maks`}</em>{` to av disse på en side, siden de er veldig dominerende.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Funksjonelt representerer den flytende handlingsknappen (FAB) den mest relevante
eller vanligste brukerhandlingen i grensesnittet. Den flyter over alt innhold på
skjermen, vanligvis i en sirkulær form med et ikon i midten. FABs kommer i tre varianter:
default, mini og extended.`}</p>
    <p>{`Extended FAB er bredere og inneholder en tekst. I motsetning til default FABs, krever ikke extended FAB et ikon.`}</p>
    <p>{`Det er innafor å bruke bare ikoner i FABs, men bare hvis man sørger for at de er
kontekstrelevante, intuitive og tydelig illustrerer handlingene for brukerne.
En FAB skal ikke inneholde handlinger som finnes andre steder på skjermen.`}</p>
    <h3 {...{
      "id": "eksempel"
    }}>{`Eksempel`}</h3>
    <p>{`Her er et eksempel med FABs som ligger over kartet i reiseplanleggeren, hvor man fremhever de handlingene som er mest relevant og oftest brukt i denne applikasjonen.`}</p>
    <img src={FabExample} alt="Kart som illustrerer bruk av floating action button" />
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="success" src={FabDo} mdxType="DoDontCard">
    Bruk FABs for primære, positive handlinger som for eksempel søk, legg til,
    rediger, innstillinger, favoritt, dele, chat osv.
  </DoDontCard>
  <DoDontCard variant="negative" src={FabDont} mdxType="DoDontCard">
    Unngå å bruk en FAB for mindre, uklare eller begrensede handlinger som for
    eksempel arkiver/søppel, varsler/feil, kontroller for å justere tekst,
    klipp/lim eller skriftfarge som hører hjemme i en verktøylinje.
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      